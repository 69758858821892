import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.error.cause.js";
import { defineComponent, reactive, onMounted, watch, ref, computed, onBeforeUnmount } from 'vue';
import { licenseList, generateLicense, licenseAvailable, licenseExportUserKeys } from '@/api/license/license';
import { ElMessage } from 'element-plus';
import downFile from '@/utils/downFile';
import clipboard from 'clipboardy';
import copy from 'copy-to-clipboard';
import { i18n } from '@/assets/i18n';
export default defineComponent({
  setup() {
    const FormRef = ref();
    const lang = ref(i18n.global.locale.value);

    const init = function (page) {
      setupData.loading = true;
      setupData.pageAllSelect = false;
      setupData.pageIndeterminate = false;
      licenseList({
        page: page,
        size: setupData.size
      }).then(res => {
        if (setupData.allSelect) {
          setupData.pageAllSelect = true;
          res.data.lists = res.data.lists.map(item => {
            return { ...item,
              selectStatus: item.status == 0
            };
          });
        }

        setupData.keyList = (res.data.lists || []).map(item => item.key);
        setupData.dataList = res.data.lists;
        setupData.size = res.data.size;
        setupData.page = res.data.page;
        setupData.total = res.data.total;
      }).finally(() => {
        setupData.loading = false;
      });
    };

    const handleLicense = function (data) {
      setupData.currentLicenseInfo = data;

      if (data.status == 0) {
        setupData.dialogLicense = true;
        setupData.isShowQueation = false;
        setupData.isNotMid = false;
      }

      if (data.status == 1) {
        Generate(1);
      }
    };

    const Generate = function (status) {
      if (status == 0) {
        setupData.FormRef && setupData.FormRef.validate(valid => {
          if (!valid) return;
          generateLicense({
            license_id: setupData.currentLicenseInfo.id,
            mid: setupData.form.mid1 + setupData.form.mid2 + setupData.form.mid3 + setupData.form.mid4,
            email: setupData.form.email
          }).then(() => {
            init(setupData.page);
            getLicenseAvailable();
            setupData.dialogResult = true;
          }).finally(() => {
            setupData.dialogLicense = false;
          });
        });
      } else {
        generateLicense({
          license_id: setupData.currentLicenseInfo.id,
          mid: setupData.form.mid1 + setupData.form.mid2 + setupData.form.mid3 + setupData.form.mid4
        }).then(res => {
          // if(setupData.sendEmail){
          //     setupData.dialogResult = true
          // }
          downFile('license.key', res.data);
        }).finally(() => {
          setupData.dialogLicense = false;
        });
      }
    };

    const getLicenseAvailable = function () {
      licenseAvailable({
        page: 1,
        size: 1
      }).then(res => {
        setupData.num = res.data.total;
      });
    };

    const questionShow = function () {
      setupData.isShowQueation = !setupData.isShowQueation;
    };

    let mousDownFun = null;
    onMounted(function () {
      getLicenseAvailable();
      init(1);
      mousDownFun = document.addEventListener("mousedown", e => {
        let t = document.getElementsByClassName('question-tooltip')[0]; // 最外层元素

        let m = document.getElementsByClassName('mid-dialog')[0];
        const paths = e.path || e.composedPath && e.composedPath() || '';

        if (!paths.includes(m) && !setupData.isShowQueation) {
          setupData.dialogLicense = false;
        }

        if (!paths.includes(t) && setupData.dialogLicense) {
          setupData.isShowQueation = false;
          setupData.dialogLicense = true;
        }
      }, false);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('mousedown', mousDownFun);
    });
    let currentLicenseInfo = {
      status: NaN,
      id: NaN
    };
    let verfiyMid = {
      type: ['string', 'number'],
      trigger: ['change', 'blur'],
      validator: function (rule, value, callback) {
        let mid = setupData.form.mid1 + setupData.form.mid2 + setupData.form.mid3 + setupData.form.mid4;

        if (setupData.keyList.includes(mid)) {
          setupData.isNotMid = true;
        } else {
          setupData.isNotMid = false;
        }

        if (setupData.isNotMid) return;

        if (!value) {
          return callback(new Error(i18n.global.t('Please enter MID')));
        }

        let reg = /[a-zA-Z0-9]{8}/;

        if (!reg.test(String(value))) {
          return callback(new Error(i18n.global.t('Please enter the correct MID')));
        }

        return callback();
      }
    };
    let rules = {
      mid1: verfiyMid,
      mid2: verfiyMid,
      mid3: verfiyMid,
      mid4: verfiyMid,
      email: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please enter your email.')));
          }

          const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

          if (!reg.test(value)) {
            return callback(new Error(i18n.global.t('Please enter the correct email address.')));
          }

          return callback();
        }

      }
    };

    const change = function (status) {
      if (!status) {
        setupData.allSelect = false;
      }

      let currStatus = setupData.dataList.find(item => {
        return item['status'] == 0 && !item['selectStatus'];
      });

      if (!currStatus) {
        setupData.pageAllSelect = true;

        if (setupData.dataList.find(item => item.status == 1)) {
          setupData.pageIndeterminate = true;
        } else {
          setupData.pageIndeterminate = false;
        } // setupData.pageAllSelect = true
        // setupData.pageIndeterminate = false

      } else {
        setupData.pageAllSelect = false;

        if (setupData.dataList.find(item => {
          return item['status'] == 0 && item['selectStatus'];
        })) {
          setupData.pageIndeterminate = true;
        } else {
          setupData.pageIndeterminate = false;
        }
      }
    };

    const pageChange = function (status) {
      if (status) {
        if (setupData.dataList.find(item => item.status == 1)) {
          setupData.pageIndeterminate = true;
        } else {
          setupData.pageIndeterminate = false;
          setupData.pageAllSelect = true;
        }

        setupData.dataList.forEach((item, key) => {
          if (item['status'] == 0 && !item['selectStatus']) {
            setupData.dataList[key]['selectStatus'] = true;
          }
        });
      } else {
        setupData.pageIndeterminate = false;
        setupData.allSelect = false;
        setupData.dataList.forEach((item, key) => {
          if (item['status'] == 0 && item['selectStatus']) {
            setupData.dataList[key]['selectStatus'] = false;
          }
        });
      }
    };

    const allSelectChange = function (status) {
      setupData.pageIndeterminate = false;

      if (status) {
        if (setupData.dataList.find(item => item.status == 1)) {
          setupData.pageIndeterminate = true;
        } else {
          setupData.pageIndeterminate = false;
        }

        setupData.pageAllSelect = true;
        setupData.dataList.forEach((item, key) => {
          if (item['status'] == 0 && !item['selectStatus']) {
            setupData.dataList[key]['selectStatus'] = true;
          }
        });
        setupData.dataList.forEach((item, key) => {
          if (item['status'] == 0 && !item['selectStatus']) {
            setupData.dataList[key]['selectStatus'] = true;
          }
        });
      } else {
        setupData.pageAllSelect = false;
        setupData.dataList.forEach((item, key) => {
          if (item['status'] == 0 && item['selectStatus']) {
            setupData.dataList[key]['selectStatus'] = false;
          }
        });
      }
    };

    const exportFile = function () {
      let arr = setupData.dataList.filter(item => item.selectStatus).map(item => item.id);

      if (!setupData.allSelect && arr.length == 0) {
        ElMessage({
          showClose: true,
          message: i18n.global.t('Please select the activation code to export'),
          type: 'error'
        });
        return;
      }

      if (setupData.allSelect) arr = [];
      licenseExportUserKeys({
        license_ids: arr
      }).then(res => {
        console.log(res.data);

        if (res.data.length == 0) {
          ElMessage({
            showClose: true,
            message: i18n.global.t('No Data'),
            type: 'warning'
          });
          return;
        }

        let fileData = res.data.reduce((total, prev) => {
          total.push(`${prev.id}, ${prev.key}`);
          return total;
        }, ['id, Activation code']).join('\n');
        downFile('Activation code.csv', fileData, () => {
          ElMessage({
            showClose: true,
            message: 'Success!',
            type: 'success'
          });
        });
      });
    };

    let computedHeight = computed(() => {
      return document.documentElement.clientHeight - 50 - 36 - 207 - 97;
    });

    const getValue = value => {
      return setupData.lang == 'en' ? value.slice(0, 15) + '...' : value;
    };

    let dataList = [];
    let setupData = reactive({
      dataList,
      handleLicense,
      Generate,
      questionShow,
      isShowQueation: false,
      isNotMid: false,
      keyList: [],
      lang,
      page: 1,
      size: 20,
      total: 0,
      loading: false,
      dialogLicense: false,
      dialogResult: false,
      currentLicenseInfo,
      sendEmail: false,
      form: {
        mid1: "",
        mid2: '',
        mid3: '',
        mid4: "",
        email: ""
      },
      rules,
      init,
      FormRef,
      num: 0,
      allSelect: false,
      pageAllSelect: false,
      pageIndeterminate: false,
      change,
      pageChange,
      allSelectChange,
      exportFile,
      getValue,

      dateComputed(num) {
        // if(!num) return ''
        // let year = Math.floor(num/12)
        // let month = num%12
        // return `${year ? (year+'年') : ''}${month ? (month+'个月') : ''}`
        if (num > 1) {
          return `${num} ${i18n.global.t('monthsDate')}`;
        } else if (num == -1) {
          return `7 ${i18n.global.t('days')}`;
        } else {
          return `${num} ${i18n.global.t('monthDate')}`;
        }
      },

      tableHeader() {
        return 'tableHeader';
      },

      copyKey(val) {
        copy(val);
        ElMessage({
          showClose: true,
          message: 'Success!',
          type: 'success'
        });
      },

      async paste() {
        await clipboard.read().then(res => {
          if (!res || !res.trim()) return;

          if (res.length >= 32) {
            setupData.form.mid1 = res.substr(0, 8);
            setupData.form.mid2 = res.substr(8, 8);
            setupData.form.mid3 = res.substr(16, 8);
            setupData.form.mid4 = res.substr(24, 8);
          }

          console.log(setupData.form, "setupData.form");
        });
      },

      computedHeight
    });
    watch([() => setupData.dialogLicense, () => setupData.dialogResult], ([dialogLicense, dialogResult]) => {
      if (!dialogLicense && !dialogResult) {
        setupData.form = {
          mid1: "",
          mid2: '',
          mid3: '',
          mid4: "",
          email: ""
        };
      }
    });
    return setupData;
  }

});