import { Request } from '../request'

//申请试用
export function trial(params: {
    name: string
    mid: string
    email: string
    license_type_id: string
    country: string,
    position: string
    use: string
    company: string,
    code: string,
    uuid: string
}){
    return Request.axiosInstance({
        url: '/license/trial',
        method: 'get',
        params
    })
}

