import { Request } from '../request'

// 获取已发布版本列表
export function getVersions():any {
  return Request.axiosInstance({
    url: '/versions',
    method: 'get'
  })
}

// 获取下载列表
export function getVersionList(){
  return Request.axiosInstance({
    url: '/version-list',
    method: 'get'
  })
}