import { defineComponent, reactive } from 'vue';
import { retrievePasswordMail } from '@/api/user/user';
import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
  name: 'ResetPassword',

  setup() {
    const ROUTER = useRouter();
    const ROUTE = useRoute();
    const TOKEN = ROUTE.query.token;

    const retrievePasswordMailFn = () => {
      retrievePasswordMail({
        token: TOKEN,
        password: resetPasswordModule.password,
        confirmPassword: resetPasswordModule.confirmPassword
      }).then(res => {
        if (res.statusCode === 200) {
          resetPasswordModule.isSuccess = true;
          setTimeout(() => {
            toLogin();
          }, 3000);
        } else {
          resetPasswordModule.isSuccess = false;
        }
      }).catch(err => {
        resetPasswordModule.isSuccess = false;
      });
    }; // 去登陆


    const toLogin = () => {
      ROUTER.replace({
        name: "SignIn"
      });
    };

    let resetPasswordModule = reactive({
      password: '',
      confirmPassword: '',
      isSuccess: false,
      toLogin,
      retrievePasswordMailFn
    });
    return resetPasswordModule;
  }

});