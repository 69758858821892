import { defineComponent, reactive, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "PayResult",

  setup() {
    const router = useRouter();
    const route = useRoute();
    const local = useI18n();
    let oneYuan = route.params.oneYuan;
    let time;

    if (oneYuan === '0') {
      // 非一元试用自动跳转
      time = setInterval(() => {
        if (setupData.timeText == 0) {
          clearTimeout(time);
          router.replace({
            name: "licenseManagement"
          });
          return;
        }

        setupData.timeText--;
      }, 1000);
    }

    onBeforeUnmount(() => {
      if (oneYuan === '0') time && clearTimeout(time);
    });
    let setupData = reactive({
      timeText: 5,
      oneYuan,
      lang: local.locale,

      toLicMan() {
        router.replace({
          name: "licenseManagement"
        });
      },

      toPage() {
        window.open('https://t.me/gobies');
      }

    });
    return setupData;
  }

});