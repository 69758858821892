import { ref, computed } from 'vue'
import { useStore } from 'vuex';
import aDown from "./down"
export default function downGoby(){
    const store = useStore();
    const currIndex = ref(-1);
    const changeCurrIndex = ref(function(index){
        
        if(index == currIndex.value){
            currIndex.value = -1
            return;
        }
        currIndex.value = index
    })
    
    const show = ref(false)
    const changeShow = ref(function(val:boolean){
        show.value = val 
    })


    const downList = computed(()=>store.state.downList)

    const down = ref((edition, system)=>{
        const data = downList.value.find(item=>item.id == edition)
        
        if(!data) return;
        let url
        switch (system) {
            case 0:
                url = data['win_download_url'];
                break;
            case 1:
                url = data['mac_download_url'];
                break;
            case 2:
                url = data['linux_download_url'];
                break;
            default:
                break;
        }
        if(!url) return
        aDown(url)
    })

    return {currIndex, changeCurrIndex, down, show, changeShow}
}