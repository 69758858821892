import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { register, getCaptcha } from '@/api/user/user';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import routerJump from '@/utils/router';
import { setSignUpInfo } from '@/utils/auth';
export default defineComponent({
  name: 'SignIn',

  setup() {
    onMounted(() => {
      getCaptchaFn();
    });
    let router = useRouter(); // 注册相关

    const signUpFn = () => {
      register({
        username: signUpModule.username,
        password: signUpModule.password,
        email: signUpModule.email,
        confirmPassword: signUpModule.confirmPassword,
        agreement: signUpModule.agreement.toString(),
        code: signUpModule.code,
        uuid: signUpModule.uuid
      }).then(res => {
        if (res.statusCode === 200) {
          setSignUpInfo(signUpModule.email, res.data.id);
          router.replace({
            name: "ActivateMailbox"
          });
          ElMessage({
            message: 'Success',
            type: 'success'
          });
        } else {
          getCaptchaFn();
          signUpModule.code = '';
        }
      }).catch(err => {
        getCaptchaFn();
        signUpModule.code = '';
      });
    }; // 获取验证码


    const getCaptchaFn = () => {
      getCaptcha().then(res => {
        if (res.statusCode === 200) {
          signUpModule.codeImg = res.data.captcha;
          signUpModule.uuid = res.data.uuid;
        }
      });
    }; // 路由跳转


    const toPage = path => {
      routerJump(path);
    };

    let signUpModule = reactive({
      username: '',
      password: '',
      email: '',
      confirmPassword: '',
      agreement: false,
      code: '',
      codeImg: '',
      uuid: '',
      signUpFn,
      getCaptchaFn,
      toPage,
      routerJump
    });
    return { ...toRefs(signUpModule)
    };
  }

});