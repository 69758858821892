import { defineComponent, onMounted, nextTick } from 'vue';
export default defineComponent({
  name: 'DocView',

  setup() {
    onMounted(() => {
      nextTick(() => {
        $("#toc").tocify({
          selectors: "h1,h2,h3,h4,h5,h6"
        });
      });
    });
    return {};
  }

});