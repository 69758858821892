import Faq from '@/views/faq/Faq.vue'

export default {
  routers: [
		{
			path: '/faq',
			name: 'faq',
			component: Faq
		}
	]
}