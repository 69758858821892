import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { getPlugins } from '@/api/extensions/extensions';
import { getVersions } from '@/api/home/home';
export default defineComponent({
  name: 'FeaturesView',

  setup() {
    onMounted(() => {
      getVersionsFn();
    }); // 插件相关
    // 获取插件

    const getPluginsFn = () => {
      getPlugins({
        page: '1',
        size: '10000',
        keyword: pluginsModule.keyword,
        engines: pluginsModule.engines,
        category: ''
      }).then(res => {
        if (res.statusCode === 200) {
          pluginsModule.pluginsList = res.data.lists;
          pluginsModule.pluginsList.forEach((v, key) => {
            v.userImg = 'https://goby-assets.oss-cn-beijing.aliyuncs.com/static_front/img/' + v.publisher + '.png';
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }; // 获取当前最新官网版本


    const getVersionsFn = () => {
      getVersions().then(res => {
        if (res.statusCode === 200) {
          pluginsModule.engines = res.data[0].version;
          getPluginsFn();
        }
      }).catch(err => {
        console.log(1);
      });
    };

    let pluginsModule = reactive({
      keyword: '',
      engines: '',
      pluginsList: [],
      getPluginsFn,

      headError(e) {
        e.target.src = require('@/assets/img/error.png');
      }

    });
    return { ...toRefs(pluginsModule)
    };
  }

});