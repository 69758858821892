import { defineComponent, onBeforeMount, computed, reactive, onMounted } from 'vue';
import HeaderCpt from '@/components/HeaderCpt.vue';
import FooterCpt from '@/components/FooterCpt.vue';
import { getUserInfo } from '@/utils/auth';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getVersionList } from '@/api/home/home';
export default defineComponent({
  name: 'App',
  components: {
    HeaderCpt,
    FooterCpt
  },

  setup() {
    let route = useRoute();
    let store = useStore();
    let mainApp = computed(() => {
      return route.meta.mainApp;
    });
    onBeforeMount(() => {
      let userInfo = getUserInfo();
      store.commit('changeUserInfo', userInfo);
    });
    onMounted(() => {
      Promise.resolve().then(() => {
        getVersionList().then(res => {
          let newData = [res.data[0], res.data[3], res.data[1], res.data[2]];
          store.commit('setDownList', newData);
        });
      });
    });
    let setupData = reactive({
      mainApp
    });
    return setupData;
  }

});