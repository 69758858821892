import { defineComponent, reactive, onMounted, ref, watch, computed } from 'vue';
import { orderList, orderDetail } from '@/api/order/order';
import { ElLoading, ElMessage } from 'element-plus';
import copy from 'copy-to-clipboard';
import { orderPay } from '@/api/sale/sale';
import { i18n } from '@/assets/i18n';
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: ['pay_status'],

  setup(props) {
    onMounted(function () {
      init(1);
    });
    const local = useI18n();

    const init = function (page) {
      setupData.loading = true;
      orderList({
        page: page,
        size: setupData.size,
        pay_status: props.pay_status
      }).then(res => {
        setupData.dataList = res.data.lists;
        setupData.size = res.data.size;
        setupData.page = res.data.page;
        setupData.total = res.data.total;
      }).finally(() => {
        setupData.loading = false;
      });
    };

    watch(() => props.pay_status, () => {
      setupData.page = 1;
      init(1);
    });
    const orderDetailDom = ref();

    const viewDetail = function (id) {
      setupData.dialogVisible = true;
      setupData.loading = true;
      orderDetail({
        order_no: id
      }).then(res => {
        setupData.currOrder = res.data;
        setupData.dialogVisible = true;
      }).finally(() => {
        setupData.loading = false;
      });
    };

    const router = useRouter();
    console.log(i18n, '------------------');

    const pay = function (order_no, payment_type, payment_price) {
      const loading = ElLoading.service({
        lock: true,
        text: i18n.global.t('Redirecting to the third-party payment page')
      });

      switch (payment_type) {
        case "paypal":
          return orderPay({
            order_no: order_no
          }).then(result => {
            window.location.href = result.data;
          }).catch(() => {
            loading.close();
          });

        case "alipay":
        case "wechat_pay":
        case "company_pay":
          loading.close();
          return router.push({
            name: 'CheckStand',
            params: {
              order_no: order_no,
              oneYuan: payment_price === '1.00' ? 1 : 0
            }
          });
      }

      orderPay({
        order_no: order_no
      }).then(result => {
        window.location.href = result.data;
      }).catch(() => {
        loading.close();
      });
    };

    let computedHeight = computed(() => {
      return document.documentElement.clientHeight - 50 - 36 - 196 - 97;
    });
    let currOrder = {};
    let setupData = reactive({
      page: 1,
      size: 20,
      total: 0,
      loading: false,
      dataList: [],
      init,
      viewDetail,
      dialogVisible: false,
      currOrder,
      orderDetailDom,
      pay,

      tableHeader() {
        return 'tableHeader';
      },

      cellClassName() {
        return 'cell-class-name';
      },

      copyKey(val) {
        if (!val) return;
        copy(val);
        ElMessage({
          showClose: true,
          message: 'Success!',
          type: 'success'
        });
      },

      dateComputed(num) {
        // if(!num) return ''
        // let year = Math.floor(num/12)
        // let month = num%12
        // return `${year ? (year+'年') : ''}${month ? (month+'个月') : ''}`
        if (num > 1) {
          return `${num} ${i18n.global.t('monthsDate')}`;
        } else if (num == -1) {
          return `7 ${i18n.global.t('days')}`;
        } else {
          return `${num} ${i18n.global.t('monthDate')}`;
        }
      },

      computedHeight,
      lang: local.locale
    });
    watch(() => setupData.dialogVisible, dialogVisible => {
      if (!dialogVisible) {
        setupData.currOrder = {};
      }
    });
    return setupData;
  }

});