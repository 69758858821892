const Token = 'token'
const Username = 'username'
const UserInfo = 'userInfo'
const SignUpEmail = 'signUpEmail'
const SignUpId = 'signUpId'
const RouterFrom = 'routerFrom'
const key = "GOBY-0123456789ACDEFHIJKLMNPQRSTUVWXZ";

import store from '@/store/index'
import router from '@/router/index'

// 存储auth相关
export function setAuth(token:string, username:string, userInfo:string):void {
  localStorage.setItem(Token, token);
  localStorage.setItem(Username, username);
  localStorage.setItem(UserInfo, toCode(userInfo));
  store.commit('changeUserInfo', JSON.parse(userInfo));
}

// 获取token
export function getToken():string | null {
  return localStorage.getItem(Token);
}

// 移除auth相关
export function removeAuth():void {
  localStorage.setItem(Token, '');
  localStorage.setItem(Username, '');
  localStorage.setItem(UserInfo, '');
  store.commit('changeUserInfo', {});
}

// 获取全部用户信息
export function getUserInfo() {
  const UserJson:string | null = localStorage.getItem(UserInfo);
  if (UserJson) {
    try {
      return JSON.parse(fromCode(UserJson));
    } catch (error) {
      removeAuth()
      // router.push({
      //   name: 'SignIn'
      // })
      return {}
    }
  } else {
    removeAuth()
    // router.push({
    //   name: 'SignIn'
    // })
    return {};
  }
}

// 存储注册邮箱
export function setSignUpInfo(email:string, id:string):void {
  localStorage.setItem(SignUpEmail, email);
  localStorage.setItem(SignUpId, id);
}

// 获取注册邮箱、用户id等
export function getSignUpInfo() {
  const ActivateEmail:string | null = localStorage.getItem(SignUpEmail);
  const ActivateId:string | null = localStorage.getItem(SignUpId);
  return {
    email: ActivateEmail,
    id: ActivateId
  };
}

// 存储路由跳转前
export function setRouterFrom(path:any):void {
  localStorage.setItem(RouterFrom, path);
}

// 获取路由跳转前
export function getRouterFrom() {
  return localStorage.getItem(RouterFrom);
}

const toCode = function (str) {  //加密字符串
  const l = key.length;  //获取密钥的长度
  const a = key.split("");  //把密钥字符串转换为字符数组
  let s = "",b, b1, b2, b3;  //定义临时变量
  for (let i = 0; i <str.length; i ++) {  //遍历字符串
      b = str.charCodeAt(i);  //逐个提取每个字符，并获取Unicode编码值
      b1 = b % l;  //求Unicode编码值得余数
      b = (b - b1) / l;  //求最大倍数
      b2 = b % l;  //求最大倍数的于是
      b = (b - b2) / l;  //求最大倍数
      b3 = b % l;  //求最大倍数的余数
      s += a[b3] + a[b2] + a[b1];  //根据余数值映射到密钥中对应下标位置的字符
  }
  return s;  //返回这些映射的字符
} 

const fromCode = function (str) {
  const l = key.length;  //获取密钥的长度
  let b, b1, b2, b3, d = 0;  //定义临时变量
  const s = new Array(Math.floor(str.length / 3));  //计算加密字符串包含的字符数，并定义数组
  b = s.length;  //获取数组的长度
  for (let i = 0; i < b; i ++) {  //以数组的长度循环次数，遍历加密字符串
      b1 = key.indexOf(str.charAt(d));  //截取周期内第一个字符串，计算在密钥中的下标值
      d ++;
      b2 = key.indexOf(str.charAt(d));  //截取周期内第二个字符串，计算在密钥中的下标值
      d ++;
      b3 = key.indexOf(str.charAt(d));  //截取周期内第三个字符串，计算在密钥中的下标值
      d ++;
      s[i] = b1 * l * l + b2 * l + b3  //利用下标值，反推被加密字符的Unicode编码值
  }
  b = s.map(item=>String.fromCharCode(item)).join('')
  return b ;  //返回被解密的字符串
}