import { defineComponent, reactive } from 'vue';
import { sendRetrievePasswordMail } from '@/api/user/user';
export default defineComponent({
  name: 'SendMail',

  setup() {
    const sendRetrievePasswordMailFn = () => {
      sendRetrievePasswordMail({
        username: sendMailModule.username,
        email: sendMailModule.email
      }).then(res => {
        if (res.statusCode === 200) {
          sendMailModule.isSuccess = true;
        } else {
          sendMailModule.isSuccess = false;
        }
      }).catch(() => {
        sendMailModule.isSuccess = false;
      });
    };

    let sendMailModule = reactive({
      username: '',
      email: '',
      isSuccess: false,
      sendRetrievePasswordMailFn
    });
    return sendMailModule;
  }

});