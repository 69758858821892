import Exp from '@/views/exp/Exp.vue'
import GoExp from '@/views/exp/GoExp.vue'

export default {
  routers: [
		{
			path: '/exp',
			name: 'exp',
			component: Exp
		},
    {
			path: '/goexp',
			name: 'goexp',
			component: GoExp
		}
	]
}