import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_result = _resolveComponent("el-result");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_result, {
    icon: "error",
    title: _ctx.$t('Payment Failed'),
    "sub-title": _ctx.$t('In order not to affect your use of the product, please pay in time!')
  }, {
    extra: _withCtx(() => [_createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = //@ts-ignore
      (...args) => _ctx.pay && _ctx.pay(...args)),
      class: "pointer"
    }, _toDisplayString(_ctx.$t("Pay Now")), 1)]),
    _: 1
  }, 8, ["title", "sub-title"])]);
}