import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-9894a9a6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "orderHeader"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_OrderItem = _resolveComponent("OrderItem");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString(_ctx.$t("All orders")), 1), _createVNode(_component_el_select, {
    modelValue: _ctx.pay_status,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.pay_status = $event),
    class: "selectStatus"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatusList, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: _ctx.$t(item.label),
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])]), _createVNode(_component_OrderItem, {
    pay_status: _ctx.pay_status
  }, null, 8, ["pay_status"])]);
}