import SignIn from '@/views/user/SignIn.vue'
import Center from '@/views/user/center/Center.vue'
import Profile from '@/views/user/center/components/Profile.vue'
import LicenseManagement from '@/views/user/center/components/LicenseManagement.vue'
import OrderManagement from '@/views/user/center/components/OrderManagement.vue'
import SignUp from '@/views/user/SignUp.vue'
import ModifyInfo from '@/views/user/ModifyInfo.vue'
import Activate from '@/views/user/activate/Activate.vue'
import ActivateMailbox from '@/views/user/activate/components/ActivateMailbox.vue'
import ActivateMailboxResult from '@/views/user/activate/components/ActivateMailboxResult.vue'
import RetrievePassword from '@/views/user/retrievePassword/RetrievePassword.vue'
import ResetPassword from '@/views/user/retrievePassword/components/ResetPassword.vue'
import SendMail from '@/views/user/retrievePassword/components/SendMail.vue'
import CrossLogin from '@/views/user/CrossLogin.vue'

export default {
    routers: [
        {
            path: '/signIn',
            name: 'SignIn',
            component: SignIn
        },
        {
            path: '/center',
            name: 'center',
            component: Center,
            meta: {
                needToken: true,
                mainApp: true
            },
            redirect: {
                name: 'profile'
            },
            children: [
                {
                    path: 'profile',
                    name: 'profile',
                    component: Profile,
                },
                {
                    path: 'licenseManagement',
                    name: 'licenseManagement',
                    component: LicenseManagement
                },
                {
                    path: 'orderManagement',
                    name: 'orderManagement',
                    component: OrderManagement
                }
            ]
        },
        {
            path: '/signUp',
            name: 'signUp',
            component: SignUp
        },
        {
            path: '/activate/',
            name: 'activate',
            component: Activate,
            redirect: {
                name: 'signUp'
            },
            children: [
                {
                    path: 'mail',
                    name: 'ActivateMailbox',
                    component: ActivateMailbox,
                },
                {
                    path: 'result',
                    name: 'ActivateMailboxResult',
                    component: ActivateMailboxResult
                }
            ]
        },
        {
            path: '/password/',
            name: 'RetrievePassword',
            component: RetrievePassword,
            redirect: {
                name: 'signUp'
            },
            children: [
                {
                    path: 'reset',
                    name: 'ResetPassword',
                    component: ResetPassword,
                },
                {
                    path: 'send',
                    name: 'SendMail',
                    component: SendMail
                }
            ]
        },
        {
            path: '/modify',
            name: 'ModifyInfo',
            meta: {
                mainApp: true
            },
            component: ModifyInfo
        },
        {
            path: "/crossLogin",
            name: "CrossLogin",
            component: CrossLogin
        }
    ]
}