import { Request } from '../request'

// 获取字典
export function getDict(data:{
    dicts: Array<string>
}) {
  return Request.axiosInstance({
    url: '/dict',
    method: 'post',
    data
  })
}