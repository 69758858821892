import "core-js/modules/es.error.cause.js";
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'; // import country from "@/assets/data/country.json"
// import { getCountryList } from "@/api/country/country"

import { trial } from "@/api/trial/trial";
import { i18n } from '@/assets/i18n';
import clipboard from 'clipboardy';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import { getDict } from "@/api/dict/dict";
import { format, subDays } from 'date-fns';
import { getCaptcha } from "@/api/user/user";
export default defineComponent({
  name: "TrialView",

  setup() {
    const route = useRoute();
    const local = useI18n();
    const router = useRouter();
    let store = useStore();
    let edition = String(route.params.edition);
    const formRef = ref();

    const init = function () {
      // getCountryList().then(res=>{
      //     // console.log(res);
      //     setupData.country = res.data
      // })
      getDict({
        dicts: ['use', 'position', 'country']
      }).then(res => {
        setupData.country = res.data.country;
        setupData.useArr = res.data.use;
        setupData.positionArr = res.data.position;
      });
      store.state.userInfo.email && (setupData.formInline.email = store.state.userInfo.email);
      store.state.userInfo.name && (setupData.formInline.name = store.state.userInfo.name);
      store.state.userInfo.country && (setupData.formInline.country = store.state.userInfo.country);
    };

    onMounted(() => {
      if (!edition) {
        router.replace({
          name: 'sale'
        });
      }

      init();
      getCaptchaFn();
    });
    let formInline = {
      mid1: "",
      mid2: '',
      mid3: '',
      mid4: "",
      email: "",
      country: "",
      name: "",
      company: "",
      position: "",
      purpose: "",
      code: ""
    };
    let verfiyMid = {
      type: ['string', 'number'],
      trigger: 'blur',
      validator: function (rule, value, callback) {
        if (!value) {
          return callback(i18n.global.t('Please enter MID'));
        }

        let reg = /[a-zA-Z0-9]{8}/;

        if (!reg.test(String(value))) {
          return callback(new Error(i18n.global.t('Please enter the correct MID')));
        }

        return callback();
      }
    };
    const rules = {
      mid1: verfiyMid,
      mid2: verfiyMid,
      mid3: verfiyMid,
      mid4: verfiyMid,
      name: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(i18n.global.t('Please enter the first and last name'));
          }

          return callback();
        }

      },
      email: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your email.')));
          }

          const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

          if (!reg.test(value)) {
            return callback(new Error(i18n.global.t('Please enter the correct email address.')));
          }

          return callback();
        }

      },
      country: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error('Please select the country'));
          }

          return callback();
        }

      },
      company: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter company name')));
          }

          return callback();
        }

      },
      position: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your job title')));
          }

          return callback();
        }

      },
      purpose: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your purpose of use')));
          }

          return callback();
        }

      },
      code: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter verification')));
          }

          return callback();
        }

      }
    }; // 计算当前日期几天前的日期， num为天数

    const subDate = num => {
      return format(subDays(new Date(), num), 'yyyy.MM.dd');
    };

    const submitForm = function (formEl) {
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          trial({
            mid: setupData.formInline.mid1 + setupData.formInline.mid2 + setupData.formInline.mid3 + setupData.formInline.mid4,
            email: setupData.formInline.email,
            name: setupData.formInline.name,
            country: setupData.formInline.country,
            license_type_id: edition,
            company: setupData.formInline.company,
            position: setupData.formInline.position,
            use: setupData.formInline.purpose,
            uuid: setupData.uuid,
            code: setupData.formInline.code
          }).then(() => {
            ElMessage({
              message: 'success!',
              type: 'success'
            });
            formEl.resetFields();
            router.push({
              name: "trialSuccess",
              params: {
                edition: edition
              }
            });
          });
        } else {
          console.log('error submit!');
          return false;
        }
      });
    };

    let codeImg = '',
        uuid = ''; // 获取验证码

    const getCaptchaFn = () => {
      getCaptcha().then(res => {
        if (res.statusCode === 200) {
          setupData.codeImg = res.data.captcha;
          setupData.uuid = res.data.uuid;
        }
      });
    };

    let setupData = reactive({
      edition,
      codeImg,
      uuid,
      formInline,
      country: [],
      formRef,
      rules,
      submitForm,

      paste() {
        clipboard.read().then(res => {
          if (!res || !res.trim()) return;

          if (res.length >= 32) {
            setupData.formInline.mid1 = res.substr(0, 8);
            setupData.formInline.mid2 = res.substr(8, 8);
            setupData.formInline.mid3 = res.substr(16, 8);
            setupData.formInline.mid4 = res.substr(24, 8);
          }
        });
      },

      useArr: [],
      positionArr: [],
      local,
      subDate,
      getCaptchaFn
    });
    return setupData;
  }

});