import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7838627a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "VerifyOrderForm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    model: _ctx.form,
    disabled: "",
    "label-width": "auto",
    "label-position": "left",
    ref: "formRef"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: _ctx.$t('Applicant'),
      class: "is-required"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.name), 1)]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Contact Email'),
      class: "is-required"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.email), 1)]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Country'),
      class: "is-required item_block"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.country), 1)]),
      _: 1
    }, 8, ["label"]), _ctx.oneYuan === '1' ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 0,
      label: _ctx.$t('Job Title'),
      class: "is-required"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.position), 1)]),
      _: 1
    }, 8, ["label"])) : _createCommentVNode("", true), _ctx.oneYuan === '1' ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 1,
      label: _ctx.$t('Purpose'),
      class: "is-required"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.purpose), 1)]),
      _: 1
    }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Company name'),
      class: _normalizeClass(["label-w", {
        'is-required': _ctx.oneYuan === '1'
      }])
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.form.company), 1)]),
      _: 1
    }, 8, ["label", "class"])]),
    _: 1
  }, 8, ["model"])]);
}