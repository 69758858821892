import "core-js/modules/es.array.includes.js";
import { defineComponent, reactive, computed, toRefs, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from 'vuex';
import { removeAuth, getToken, setAuth } from '@/utils/auth';
import routerJump from '@/utils/router';
import { useRoute } from "vue-router";
import { getUserInfo } from '@/api/user/user';
import { i18n } from "@/assets/i18n";
export default defineComponent({
  name: 'HeaderCpt',

  setup() {
    const lang = ref(i18n.global.locale.value);
    let store = useStore();
    let userInfo = computed(() => store.state.userInfo);
    let route = useRoute();
    let routeName = computed(() => route.name);

    const toggleDocDropdown = () => {
      headerModule.showDocDropdown = !headerModule.showDocDropdown;
    };

    const signOut = () => {
      removeAuth();
      routerJump('/');
    };

    const toPage = path => {
      routerJump(path);
    };

    const getUrl = type => {
      let url = window.location.href || '';

      if (url.includes('10.10.10.16')) {
        return type == 'zh' ? 'http://10.10.10.160' : 'http://10.10.10.161';
      } else {
        return type == 'zh' ? 'https://gobysec.net' : 'https://gobies.org';
      }
    };

    let mousDownFun = null;
    onMounted(() => {
      const token = getToken();

      if (token) {
        Promise.resolve().then(() => {
          getUserInfo({
            index: 1
          }).then(res => {
            if (res.statusCode == 200) {
              setAuth(token, res.data.username, JSON.stringify(res.data));
            } else {
              removeAuth();
            }
          });
        });
      }

      if (headerModule.showDocDropdown) return;
      mousDownFun = document.addEventListener("mousedown", e => {
        let t = document.getElementsByClassName('dropdown-box')[0]; // 最外层元素

        const paths = e.path || e.composedPath && e.composedPath() || '';

        if (!paths.includes(t)) {
          headerModule.showDocDropdown = false;
        }
      }, false);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('mousedown', mousDownFun);
    });
    let headerModule = reactive({
      lang,
      showDocDropdown: false,
      userInfo,
      toggleDocDropdown,
      signOut,
      toPage,
      getUrl,
      routeName,
      mousDownFun
    });
    return { ...toRefs(headerModule)
    };
  }

});