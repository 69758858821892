import { Request } from '../request'

// 获取插件列表
export function getPlugins(parameter: {
  page: string,
  size: string,
  keyword: string,
  engines:  string,
  category: string
}):any {
  return Request.axiosInstance({
    url: '/plugins',
    method: 'get',
    params: parameter
  })
}
