import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import orderFormItem from './OrderFormItem.vue';
import verifyOrderForm from './VerifyOrderForm.vue';
import { orderAdd, orderPay } from "@/api/sale/sale";
import { orderPerAdd, paymentType } from "@/api/order/order";
import { licenseCategoryDetail } from "@/api/license/license";
import { ElMessage, ElLoading } from 'element-plus';
import { i18n } from '@/assets/i18n';
import * as _ from 'lodash';
import { getLang } from '@/utils/lang';
import { format, subDays } from 'date-fns';
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "orderForm",
  components: {
    orderFormItem,
    verifyOrderForm
  },

  setup() {
    const lang = ref(i18n.global.locale.value);
    const route = useRoute();
    const router = useRouter();
    const local = useI18n();
    let orderFormItem = ref(); // let total:ComputedRef<number> = computed(()=>{
    //     return setupData.num * (setupData.licenseDetail?.dollar || 0) * setupData.period * Number(setupData.periods)
    // })

    let formData = computed(() => {
      return { ...setupData.orderFormItem?.form
      };
    });
    let edition = route.params.edition;
    let monthNum = route.params.month;
    onMounted(() => {
      init(false);
    }); // 计算当前日期几天前的日期， num为天数

    const subDate = num => {
      return format(subDays(new Date(), num), 'yyyy.MM.dd');
    };

    function loadStart() {
      return ElLoading.service({
        body: true,
        fullscreen: true,
        lock: true,
        text: setupData.loadingText
      });
    }

    const numChange = function () {
      let nums = Number(String(setupData.num).replace(/^\.+|[^\d.]/g, '') || '');
      setupData.num = nums;
      orderPerAdds();
    };

    const orderPerAdds = _.debounce(function () {
      orderPerAdd({
        license_type_id: edition,
        payment_type: setupData.pay,
        quantity: setupData.num,
        period: setupData.oneYuan === '1' ? -1 : Number(setupData.periods),
        coupon_code: route.query && route.query.coupon_code ? route.query.coupon_code : undefined
      }).then(res => {
        console.log(res);
        setupData.periodsList = res.data.discounts;
        setupData.pay = res.data.order.payment_type;
        setupData.periods = res.data.order.period; // setupData.num = res.data.order.quantity

        setupData.total = res.data.order.order_amount;
        setupData.discount = res.data.order.discount_amount; // setupData.payList = res.data.payment_type

        setupData.currency = res.data.order.currency;
        setupData.price = res.data.order.license_type_price;
        setupData.period_day = res.data.order.period_day;
      });
    }, 200);

    const init = function (toRedTeam) {
      // let loading = loadStart()
      if (toRedTeam) {
        // 刷新当前路由
        router.push({
          name: "orderForm",
          params: {
            edition: 13,
            month: 3,
            oneYuan: 0
          }
        });
        setTimeout(() => {
          location.reload();
        }, 0);
      }

      let oneYuan = route.params.oneYuan;
      setupData.oneYuan = oneYuan;
      setupData.periods = monthNum || '3';
      licenseCategoryDetail({
        license_type_id: edition
      }).then(res => {
        setupData.licenseDetail = res.data;
      });
      paymentType().then(({
        data
      }) => {
        if (setupData.oneYuan === '1') {
          if (data.company_pay) delete data.company_pay;
        }

        setupData.payList = data;
        orderPerAdds();
      });
    };

    const setPay = function (val) {
      if (setupData.pay == val) {
        setupData.pay = "";
      } else {
        setupData.pay = val;
      }

      orderPerAdds();
    };

    const verify = function (val) {
      if (val == 0) {
        setupData.orderFormItem.verify(res => {
          if (!res) return;

          if (!setupData.pay) {
            ElMessage.error('Please select mode of payment');
            return;
          }

          changeActive(1);
        });
      }

      if (val == 1) {
        if (setupData.oneYuan === '1' && !setupData.agreement) {
          ElMessage.error('Please agree to the user agreement first');
          return;
        }

        setupData.loadingText = i18n.global.t('Order generation, please wait');
        let loading = loadStart(); // 判断是否是 1 元购买

        let params = {
          license_type_id: edition,
          payment_type: setupData.pay,
          quantity: setupData.num,
          name: setupData.formData.name,
          country: setupData.formData.country,
          company: setupData.formData.company,
          period: setupData.oneYuan === '1' ? -1 : Number(setupData.periods)
        };

        if (setupData.oneYuan === '1') {
          params.period = -1;
          params['position'] = setupData.formData.position;
          params['use'] = setupData.formData.purpose;
        }

        if (route.query && route.query.coupon_code) {
          params['coupon_code'] = route.query.coupon_code;
        }

        orderAdd(params).then(res => {
          switch (res.data.payment_type) {
            case "paypal":
              setupData.loadingText = i18n.global.t('Redirecting to the third-party payment page');
              return orderPay({
                order_no: res.data.order_no
              }).then(result => {
                window.location.href = result.data;
              }).catch(() => {
                loading.close();
              }).finally(() => {
                setupData.loadingText = "";
              });

            case "alipay":
            case "wechat_pay":
            case "company_pay":
              loading.close();
              return router.push({
                name: 'CheckStand',
                params: {
                  order_no: res.data.order_no,
                  oneYuan: setupData.oneYuan
                }
              });
          }
        }).catch(() => {
          loading.close();
        });
      }
    };

    const changeActive = function (val) {
      setupData.active = val;
    };

    const periodsSelect = function (e) {
      setTimeout(() => {
        if (!e.target.value) return;
        setupData.periods = e.target.value;
        orderPerAdds();
      }, 200);
    };

    const formatPrice = price => {
      const str = price.toString();
      const reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    };

    const isEN = getLang() === 'en';
    let setupData = reactive({
      lang,
      num: 1,
      total: "",
      pay: isEN ? "paypal" : 'wechat_pay',
      currency: isEN ? "RMB" : 'USD',
      price: 0,
      verify,
      period_day: "",
      orderFormItem,
      active: 0,
      changeActive,
      formData,
      payList: {},
      edition,
      setPay,
      licenseDetail: {
        created_at: "",
        dollar: 0,
        id: 0,
        name: "",
        name_cn: "",
        price: 0,
        sequence: 0,
        status: 0,
        updated_at: ""
      },
      periods: '',
      loadingText: "",

      toPage(val) {
        window.open(window.location.origin + val);
      },

      periodsList: [],
      periodsSelect,
      orderPerAdds,
      discount: "",
      numChange,
      formatPrice,

      getCurrencySymbol() {
        return this.currency === 'RMB' ? '￥' : '$';
      },

      oneYuan: '0',
      local,
      subDate,
      init,
      agreement: false
    });
    return setupData;
  }

});