// const Sale = import('@/views/sale/SaleView.vue')
import Sale from '@/views/sale/Sale.vue'
import Trial from '@/views/sale/components/Trial.vue'
import Buy from '@/views/sale/components/Buy.vue'
import OrderForm from "@/views/sale/components/OrderForm.vue"
import Pay from "@/views/sale/components/Pay.vue"
import PayResultSuccess from "@/views/sale/components/PayResultSuccess.vue"
import PayResultError from "@/views/sale/components/PayResultError.vue"
import TrialSuccess from "@/views/sale/components/TrialSuccess.vue"
import Chat from "@/views/sale/components/Chat.vue"
import PayPalResult from "@/views/sale/components/PayPalResult.vue"
import Agreement from '@/views/sale/components/Agreement.vue'
import ChatSuccess from "@/views/sale/components/ChatSuccess.vue"
import CheckStand from "@/views/sale/components/CheckStand.vue"

export default {
  routers: [
		{
			path: '/sale',
			name: 'sale',
			component: Sale,
            meta:{
                mainApp: true
            }
		},
        {
            path: "/trial/:edition",
            name: "trial",
            component: Trial,
            meta:{
                mainApp: true
            }
        },
        {
            path: "/trialSuccess/:edition",
            name: "trialSuccess",
            component: TrialSuccess,
            meta:{
                mainApp: true
            }
        },
        {
            path: "/chat/:edition",
            name: "chat",
            component: Chat,
            meta:{
              mainApp: true
            }
        },
        {
            path: "/chatSuccess/:edition",
            name: "ChatSuccess",
            component: ChatSuccess,
            meta:{
                mainApp: true
            }
        },
        {
            path: "/buy",
            name: "buy",
            component: Buy,
            meta: {
				needToken: true,
                mainApp: true
			},
            redirect:{
                name: 'orderForm',
            },
            children:[
                {
                    path: "orderForm",
                    redirect:{
                        name: 'sale',
                    },
                },
                {
                    path: "orderForm/:edition/:oneYuan",
                    name: "orderForm",
                    component: OrderForm
                },
                {
                    path: "pay",
                    name: "pay",
                    component: Pay
                },
                {
                    path: "payPalResult",
                    name: "payPalResult",
                    component: PayPalResult
                },
                {
                    path: "payResultSuccess/:oneYuan",
                    name: "payResultSuccess",
                    component: PayResultSuccess
                },
                {
                    path: "payResultError/:orderNo",
                    name: "payResultError",
                    component: PayResultError
                },
                {
                    path: "checkStand/:order_no/:oneYuan",
                    name: "CheckStand",
                    component: CheckStand
                },
            ]
        },
        {
            path: "/agreement",
            name: "agreement",
            component: Agreement,
            meta:{
                mainApp: true
            }
        }
	]
}
