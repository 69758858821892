import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3531fad0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bgfff trial"
};
const _hoisted_2 = {
  class: "title1"
};
const _hoisted_3 = {
  class: "mt51 pl40"
};
const _hoisted_4 = {
  class: "image-form-item"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "btn-tips"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    model: _ctx.formInline,
    rules: _ctx.rules,
    "label-position": "left",
    "label-width": "auto",
    ref: "formRef"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("Start Trial")), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_form_item, {
      label: _ctx.$t('Applicant'),
      prop: "name",
      class: "is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.formInline.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.formInline.name = $event),
        placeholder: _ctx.$t('Please enter your first and last name')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Contact Email'),
      prop: "email",
      class: "is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.formInline.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.formInline.email = $event),
        placeholder: _ctx.$t('Please enter your current email address')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Country'),
      prop: "country",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.formInline.country,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.formInline.country = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Country')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.country, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Company Name'),
      prop: "company",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.formInline.company,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.formInline.company = $event),
        placeholder: _ctx.$t('Please enter company name')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Job Title'),
      prop: "position",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.formInline.position,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.formInline.position = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Please select your job title')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positionArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Purpose'),
      prop: "purpose",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.formInline.purpose,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.formInline.purpose = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Please select your purpose of use')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.useArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Verification'),
      prop: "code",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_input, {
        modelValue: _ctx.formInline.code,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.formInline.code = $event),
        placeholder: _ctx.$t('Please enter verification code'),
        class: "lg-input ver",
        id: "captcha"
      }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("div", {
        onClick: _cache[7] || (_cache[7] = //@ts-ignore
        (...args) => _ctx.getCaptchaFn && _ctx.getCaptchaFn(...args)),
        class: "vereRight"
      }, [_createElementVNode("img", {
        id: "img110",
        style: {
          "cursor": "pointer"
        },
        src: _ctx.codeImg
      }, null, 8, _hoisted_5)])])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createElementVNode("button", {
        type: "button",
        class: "mt28 width187 height46 colorFFF bgColorMain br4 font16",
        onClick: _cache[8] || (_cache[8] = $event => _ctx.submitForm(_ctx.formRef))
      }, _toDisplayString(_ctx.$t("Submit")), 1), _createElementVNode("p", _hoisted_6, [_ctx.local.locale === 'zh' ? (_openBlock(), _createElementBlock("span", _hoisted_7, "温馨提示：申请试用，仅支持" + _toDisplayString(_ctx.subDate(14)) + "前更新的漏洞", 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('Notice: Apply for trial, only possess vulnerabilities updated before') + " " + _ctx.subDate(14)), 1))])]),
      _: 1
    })])]),
    _: 1
  }, 8, ["model", "rules"])]);
}