import { Request } from '../request'

// 登录
export function login(parameter: {username: string, password: string, code: string, uuid: string}):any {
  return Request.axiosInstance({
    url: '/user/login',
    method: 'post',
    data: parameter
  })
}

//获取用户信息
export function getUserInfo(params?:{
  index: number
}) {
  return Request.axiosInstance({
    url: '/user/info',
    method: 'get',
    params
  })
}

// 注册
export function register(parameter: 
    {
      username: string, 
      password: string, 
      email: string,
      confirmPassword: string,
      agreement: string,
      code: string,
      uuid: string
    }
  ):any {
  return Request.axiosInstance({
    url: '/user/register',
    method: 'post',
    data: parameter
  })
}

// 获取验证码
export function getCaptcha() {
  return Request.axiosInstance({
    url: '/user/captcha',
    method: 'get'
  })
}

// 更新key
export function updateKey() {
  return Request.axiosInstance({
    url: '/user/updateKey',
    method: 'post'
  })
}

// 邮箱激活
export function activate(parameter) {
  return Request.axiosInstance({
    url: '/user/activate',
    method: 'post',
    data: parameter
  })
}

// 重发激活邮件
export function resendActiveMail(parameter) {
  return Request.axiosInstance({
    url: '/user/resendActiveMail',
    method: 'post',
    data: parameter
  })
}

// 更新用户信息
export function updateInfo(parameter) {
  return Request.axiosInstance({
    url: '/user/updateInfo',
    method: 'post',
    data: parameter
  })
}

// 发送找回密码邮件
export function sendRetrievePasswordMail(data) {
  return Request.axiosInstance({
    url: '/user/send/retrievePasswordMail',
    method: 'post',
    data
  })
}

// 通过邮件token修改密码 
export function retrievePasswordMail(data) {
  return Request.axiosInstance({
    url: '/user/retrievePassword',
    method: 'post',
    data
  })
}

// 更新用户密码
export function updatePassword(data) {
  return Request.axiosInstance({
    url: '/user/updatePassword',
    method: 'post',
    data
  })
}
