import {RequestGama} from '../requestGama'
import {getLang} from '@/utils/lang'

// gamma - 获取exp文档
export function getExpDoc(): any {
    const lang = getLang() === "zh" ? 'CN' : "EN";
    return RequestGama.axiosInstance({
        url: `/doc/info/1/${lang}`,
        method: 'get'
    })
}

// gamma - 获取GoExp文档
export function getGoExp(): any {
    const lang = getLang() === "zh" ? 'CN' : "EN";
    return RequestGama.axiosInstance({
        url: `/doc/info/0/${lang}`,
        method: 'get'
    })
}
