import Features from '@/views/features/Features.vue'

export default {
  routers: [
		{
			path: '/features',
			name: 'features',
			component: Features
		}
	]
}