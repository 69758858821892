function exportRaw(name:string, data:string, callback?) {
    const urlObject = window.URL || window.webkitURL || window;
    const export_blob = new Blob([data]);
    const save_link:HTMLAnchorElement = document.createElement( "a");
    save_link.href = urlObject.createObjectURL(export_blob);
    save_link.download = name;
    fakeClick(save_link, callback);
}

function fakeClick(element:HTMLAnchorElement, callback?) {
    const ev = document.createEvent("MouseEvents");
    ev.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(ev);
    callback && callback()
}
export default exportRaw