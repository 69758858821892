import { defineComponent, reactive } from 'vue';
import OrderItem from './OrderItem.vue';
export default defineComponent({
  components: {
    OrderItem
  },

  setup() {
    let setupData = reactive({
      pay_status: -1,
      orderStatusList: [{
        value: -1,
        label: 'All orders'
      }, {
        value: 0,
        label: 'To be paid'
      }, {
        value: 1,
        label: 'Paid'
      }]
    });
    return setupData;
  }

});