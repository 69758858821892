import { defineComponent, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { orderPay } from '@/api/sale/sale';
import { ElLoading } from 'element-plus';
import { i18n } from '@/assets/i18n';
import { orderDetail } from '@/api/order/order';
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const order_no = route.params.orderNo;

    const getOrderInfo = order_no => {
      orderDetail({
        order_no: order_no
      }).then(res => {
        const loading = ElLoading.service({
          lock: true,
          text: i18n.global.t('Redirecting to the third-party payment page')
        });

        switch (res.data.payment_type) {
          case "paypal":
            return orderPay({
              order_no: order_no
            }).then(result => {
              window.location.href = result.data;
            }).catch(() => {
              loading.close();
            });

          case "alipay":
          case "wechat_pay":
          case "company_pay":
            return router.push({
              name: 'CheckStand',
              params: {
                order_no: res.data.order_no,
                oneYuan: res.data.license_type_price === '1.00' ? 1 : 0
              }
            });
        }
      });
    };

    const pay = function () {
      getOrderInfo(order_no);
    };

    let setupData = reactive({
      pay
    });
    return setupData;
  }

});