import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { login, getCaptcha } from '@/api/user/user';
import { setAuth } from '@/utils/auth';
import { useRoute, useRouter } from 'vue-router';
import routerJump from '@/utils/router';
import { ElMessage } from 'element-plus';
import { i18n } from '@/assets/i18n';
export default defineComponent({
  name: 'SignIn',

  setup() {
    onMounted(() => {
      getCaptchaFn();
    });
    let router = useRouter();
    let route = useRoute(); // 登录相关

    const signIn = () => {
      if (!signInModule.username) {
        ElMessage({
          showClose: true,
          message: `${i18n.global.t('Please enter your username or email address')}`,
          type: 'error'
        });
        return;
      } else if (!signInModule.password) {
        ElMessage({
          showClose: true,
          message: `${i18n.global.t('Please enter password')}`,
          type: 'error'
        });
        return;
      } else if (!signInModule.code) {
        ElMessage({
          showClose: true,
          message: `${i18n.global.t('Please enter the verification code')}`,
          type: 'error'
        });
        return;
      } else if (!signInModule.isAgree) {
        signInModule.isShark = true;
        setTimeout(() => {
          signInModule.isShark = false;
        }, 1000);
        ElMessage({
          showClose: true,
          message: `${i18n.global.t('Please read and check')}《${i18n.global.t('Goby user registration and service agreement')}》`,
          type: 'error'
        });
        return;
      }

      login({
        username: signInModule.username,
        password: signInModule.password,
        code: signInModule.code,
        uuid: signInModule.uuid
      }).then(res => {
        if (res.statusCode === 200) {
          let token = res.data.token;
          let username = res.data.user.username;
          let userInfo = JSON.stringify(res.data.user);
          setAuth(token, username, userInfo);
          signInModule.token = token;

          if (route.query.url) {
            router.replace(route.query.url);
          } else {
            router.replace({
              name: "profile"
            });
          }
        } else {
          getCaptchaFn();
          signInModule.code = '';
        }
      }).catch(err => {
        getCaptchaFn();
        signInModule.code = '';
      });
    }; // 获取验证码


    const getCaptchaFn = () => {
      getCaptcha().then(res => {
        if (res.statusCode === 200) {
          signInModule.codeImg = res.data.captcha;
          signInModule.uuid = res.data.uuid;
        }
      });
    };

    const toPage = path => {
      routerJump(path);
    };

    let signInModule = reactive({
      isShowServiceAgreement: false,
      isShark: false,
      isAgree: false,
      username: '',
      password: '',
      code: '',
      codeImg: '',
      uuid: '',
      token: '',
      signIn,
      toPage,
      getCaptchaFn
    });
    return { ...toRefs(signInModule)
    };
  }

});