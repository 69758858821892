import { defineComponent, reactive, onMounted } from 'vue';
import { getPocdoc } from '@/api/sale/sale';
export default defineComponent({
  name: "AgreementView",

  setup() {
    const init = function () {
      getPocdoc().then(res => {
        setupData.agreement = res.data.content;
      }).catch(() => {
        setupData.agreement = 'No Data';
      });
    };

    onMounted(() => {
      init();
    });
    let setupData = reactive({
      agreement: ""
    });
    return setupData;
  }

});