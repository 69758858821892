import { defineComponent, reactive, toRefs, onMounted, nextTick } from 'vue';
import { getExpDoc } from '@/api/exp/exp';
import Editor from '@toast-ui/editor';
export default defineComponent({
  name: 'ExpView',

  setup() {
    onMounted(() => {
      getExpDocFn();
    }); // 获取exp相关

    const getExpDocFn = () => {
      getExpDoc().then(res => {
        if (res.code === 0) {
          const elem = document.querySelector('.doc-viewer');
          expModule.expContent = res.doc.content;
          expModule.version = res.doc.version;
          expModule.updateDate = res.doc.updateDate;
          Editor.factory({
            el: elem,
            viewer: true,
            initialValue: expModule.expContent
          });
          $(".toastui-editor-contents").html(`<p class="exp-title">EXP ${expModule.version}<p><p class="exp-update">Last Update: ${expModule.updateDate}<p>${$(".toastui-editor-contents").html()}`);
          nextTick(() => {
            $("#toc").tocify({
              selectors: "h1,h2,h3,h4,h5,h6"
            });
          });
        }
      }).catch(err => {
        console.log(1);
      });
    };

    let expModule = reactive({
      expContent: '',
      version: '',
      updateDate: '',
      getExpDocFn
    });
    return { ...toRefs(expModule)
    };
  }

});