import "core-js/modules/es.error.cause.js";
import { defineComponent, reactive, ref, onMounted } from 'vue'; // import country from "@/assets/data/country.json"
// import {getCountryList} from "@/api/country/country"

import { contact } from '@/api/sale/sale';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { i18n } from '@/assets/i18n'; // import getDict from "@/utils/dict"

import { getDict } from "@/api/dict/dict"; // 引入i18n

import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "ChatView",

  setup() {
    const local = useI18n();
    let router = useRouter();
    let route = useRoute();
    let edition = route.params.edition;
    let rules = {
      name: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(i18n.global.t('Please enter the first and last name'));
          }

          return callback();
        }

      },
      // email:{
      //     type: 'string',
      //     trigger:'blur',
      //     validator(rule: any, value: any, callback: any){
      //         if(!value){
      //             return callback(new Error(i18n.global.t('Please enter your email.')))
      //         }
      //         const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      //         if(!reg.test(value)){
      //             return callback(new Error(i18n.global.t('Please enter the correct email address.')))
      //         }
      //         return callback()
      //     }
      // },
      country: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please select country')));
          }

          return callback();
        }

      },
      position: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your job title')));
          }

          return callback();
        }

      },
      purpose: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please select your purpose of use')));
          }

          return callback();
        }

      },
      company: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your company')));
          }

          return callback();
        }

      },
      mobile: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (local.locale.value == 'zh') {
            const regMobile = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
            if (!regMobile.test(String(value))) return callback(new Error(i18n.global.t('Please enter the correct phone number')));
          }

          if (!String(value).trim()) {
            return callback(new Error(i18n.global.t('Please enter your phone number')));
          }

          return callback();
        }

      }
    };

    const init = function () {
      // getCountryList().then(res=>{
      //     setupData.country = res.data
      //     setupData.form.country = local.locale.value === 'en' ? 'US' : 'CN'
      // })
      getDict({
        dicts: ['use', 'position', 'country']
      }).then(res => {
        setupData.country = res.data.country;
        setupData.form.country = local.locale.value === 'en' ? 'US' : 'CN';
        setupData.useArr = res.data.use;
        setupData.positionArr = res.data.position;
      });
    };

    onMounted(() => {
      init();
    });
    const formRef = ref();

    let verify = function (callback) {
      setupData?.formRef?.validate(valid => {
        callback(valid);
      });
    };

    const submit = function () {
      verify(valid => {
        if (valid) {
          contact({
            name: setupData.form.name,
            email: setupData.form.email,
            mobile: setupData.form.mobile,
            company: setupData.form.company,
            country: setupData.form.country,
            quantity: setupData.form.number,
            comment: setupData.form.comment,
            position: setupData.form.position,
            use: setupData.form.purpose,
            license_type_id: Number(edition)
          }).then(() => {
            setupData.form = {
              mid1: "",
              mid2: "",
              mid3: "",
              mid4: "",
              firstName: "",
              lastName: "",
              name: "",
              email: "",
              company: "",
              country: "",
              mobile: "",
              number: 1,
              comment: "",
              position: "",
              purpose: ""
            };
            ElMessage({
              message: 'Success!',
              type: 'success'
            });
            router.replace({
              name: "ChatSuccess",
              params: {
                edition
              }
            });
          });
        }
      });
    };

    let setupData = reactive({
      form: {
        mid1: "",
        mid2: "",
        mid3: "",
        mid4: "",
        firstName: "",
        lastName: "",
        name: "",
        email: "",
        company: "",
        country: "",
        mobile: "",
        number: 1,
        comment: "",
        position: "",
        purpose: ""
      },
      version: "Redteam",
      edition,
      country: [],
      rules,
      formRef,
      submit,

      toPage(val) {
        window.open(window.location.origin + val);
      },

      useArr: [],
      positionArr: [],
      local
    });
    return setupData;
  }

});