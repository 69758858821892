import { defineComponent, reactive, onMounted } from 'vue';
import { apiOrderPayCallback } from "@/api/sale/sale";
import { useRoute, useRouter } from 'vue-router';
import { ElLoading } from 'element-plus';
import { i18n } from '@/assets/i18n';
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const init = function () {
      let loading = ElLoading.service({
        lock: true,
        text: i18n.global.t('A payment request is being made to a third-party interface')
      });
      let {
        PayerID,
        orderNo,
        paymentId,
        orderId,
        success,
        token
      } = route.query;
      apiOrderPayCallback({
        PayerID: PayerID,
        orderNo: orderNo,
        paymentId: paymentId,
        orderId: orderId,
        success: success,
        token: token
      }).then(res => {
        if (res.statusCode == 200) {
          router.replace({
            name: "payResultSuccess"
          });
        } else {
          throw 'error';
        }
      }).catch(() => {
        router.replace({
          name: "payResultError",
          params: {
            orderNo: orderNo
          }
        });
      }).finally(() => {
        loading.close();
      });
    };

    onMounted(() => {
      init();
    });
    let setupData = reactive({});
    return setupData;
  }

});