import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { getUserInfo } from "@/api/user/user";
import { setAuth } from "@/utils/auth";
export default defineComponent({
  name: 'CrossLogin',

  setup() {
    console.log(444);
    let route = useRoute();
    const token = `${route.query.token}`;
    if (token) localStorage.setItem('token', token);
    getUserInfo().then(({
      data
    }) => {
      let username = data.username;
      let userInfo = JSON.stringify(data);
      setAuth(token, username, userInfo);
    });
  }

});