import { defineComponent, reactive, toRefs, onMounted, nextTick } from 'vue';
import { getFeatures } from '@/api/features/features';
export default defineComponent({
  name: 'FeaturesView',

  setup() {
    onMounted(() => {
      getFeaturesFn();
    }); // 获取faq相关

    const getFeaturesFn = () => {
      getFeatures({
        page: '1',
        size: '1000'
      }).then(res => {
        if (res.statusCode === 200) {
          const list = res.data;

          if (featuresModule.featuresContent) {
            featuresModule.featuresContent = '';
          }

          list.forEach((val, ind) => {
            featuresModule.featuresContent += '<h1 class="h-child">' + val.name + '</h1>';
            val.lists.forEach((v, k) => {
              featuresModule.featuresContent += '<h2>' + v.title + '</h2>';
              featuresModule.featuresContent += v.content.replace(/(<img[^>]* )(alt\s*=\s*['"][^'"]*['"])([^>]*>)/ig, "$1$3");
            });
          });
          nextTick(() => {
            if ($('.nav').length > 0) return;
            $("#toc").tocify();
          });
        }
      }).catch(err => {
        console.log(1);
      });
    };

    let featuresModule = reactive({
      featuresContent: '',
      getFeaturesFn
    });
    return { ...toRefs(featuresModule)
    };
  }

});