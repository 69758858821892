import Home from '@/views/home/HomeView.vue'

export default {
  routers: [
		{
			path: '/',
			name: 'home',
			component: Home
		}
	]
}