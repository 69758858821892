import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getToken, setRouterFrom } from '@/utils/auth'
import Home from './home/home'
import Sale from './sale/sale'
import User from './user/user'
import Faq from './faq/faq'
import Features from './features/features'
import Extensions from './extensions/extensions'
import Exp from './exp/exp'
import Updates from './updates/updates'

import { i18n } from '@/assets/i18n'
import { ElMessage } from 'element-plus'

const routes: Array<RouteRecordRaw> = [
  ...Home.routers,
  ...Sale.routers,
  ...User.routers,
  ...Faq.routers,
  ...Features.routers,
  ...Extensions.routers,
  ...Exp.routers,
  ...Updates.routers,
  {
    path: "/:catchAll(.*)",
    redirect: {
      name: "home"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from) => {
  if ((window as any)._hmt) {
    (window as any)._hmt.push(['_trackPageview', '/#' + to.fullPath]);
  }
  if (to.name == 'sale') {
    $('.sale').css('width', '1360px');
    $('.com-div').css('width', '1360px');
  } else {
    $('.sale').css('width', '1280px');
    $('.com-div').css('width', '1280px');
  }
  if (to.meta.needToken) {
    const TOKEN = getToken();
    if (TOKEN) {
      setRouterFrom(from.name);
      return true;
    } else {
      if(to.name == "orderForm"){
        ElMessage({
          message: i18n.global.t("Please log in to purchase a license"),
          type: 'warning',
        })
      }
      router.replace({
        name: 'SignIn',
        query: {
          url: to.fullPath
        }
      });
    }
  } else {
    setRouterFrom(from.name);
    return true;
  }
})

router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})

export default router
