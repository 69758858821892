import { defineComponent, reactive, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import downGoby from '@/components/downGoby.vue';
export default defineComponent({
  components: {
    downGoby
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    let edition = computed(() => route.params.edition); // let time = setTimeout(()=>{
    //     router.replace({
    //         name: "sale"
    //     })
    // },5000)
    // onBeforeUnmount(()=>{
    //     clearTimeout(time)
    // })

    let setupData = reactive({
      edition
    });
    return setupData;
  }

});