import {createI18n} from 'vue-i18n'
import {getLang} from '@/utils/lang'
// 国际化语言资源
const messages = {
    // 英文
    en: require('./en.json'),
    // 中文
    zh: require('./zh.json')
}


// 创建i18n实例
export const i18n = createI18n({
    legacy: false,
    locale: getLang(),
    // locale: 'zh',
    messages
})
