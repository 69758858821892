import {Request} from "../request"
import axios from 'axios'

//获取订单列表
export function orderList(params: {
    size: number
    page: number
    pay_status: number
}) {
    return Request.axiosInstance({
        url: "/order/list",
        method: "get",
        params
    })
}

// 获取订单详情
export function orderDetail(params: {
    order_no: string
}) {
    return Request.axiosInstance({
        url: "/order/detail",
        method: "get",
        params
    })
}

// 预创建订单
export function orderPerAdd(data) {
    return Request.axiosInstance({
        url: "/order/perAdd",
        method: "post",
        data
    })
}


//获取支付方式
export function paymentType() {
    return Request.axiosInstance({
        url: "/order/paymentType",
        method: "get",
    })
}

// 获取订单详情和支付二维码地址，如果传参payment_type不为空时，将切换支付方式，并刷新二维码
export function orderDetailWithPayInfo(params: {
    order_no: string,
    payment_type?: string
}) {
    return Request.axiosInstance({
        url: "/order/detailWithPayInfo",
        method: "get",
        params
    })
}

// 获取订单支付CodeUrl
export function orderCodeUrl(text: string, size: number) {
    return axios.get("https://api.oick.cn/qrcode/api.php", {
        responseType: "blob",
        params: {
            text: text,
            size: size,
        },
    }).then((res) => {
        return window.URL.createObjectURL(res.data)
    }).catch(err => console.log(err));
}


export function checkPayStatus(params: {
    order_no: string,
}) {
    return Request.axiosInstance({
        url: "/order/checkPayStatus",
        method: "get",
        params
    })
}