import { defineComponent, reactive, toRefs, onMounted, nextTick } from 'vue';
import { getFaq } from '@/api/faq/faq';
export default defineComponent({
  name: 'FaqView',

  setup() {
    onMounted(() => {
      getFaqFn();
    }); // 获取faq相关

    const getFaqFn = () => {
      getFaq().then(res => {
        if (res.statusCode === 200) {
          const list = res.data;
          list.forEach((val, ind) => {
            faqModule.faqContent += '<h1 class="h-child">' + val.name + '</h1>';
            val.lists.forEach((v, k) => {
              faqModule.faqContent += '<h2>' + v.title + '</h2>';
              faqModule.faqContent += v.content.replace(/(<img[^>]* )(alt\s*=\s*['"][^'"]*['"])([^>]*>)/ig, "$1$3");
            });
          });
          nextTick(() => {
            $("#toc").tocify();
          });
        }
      }).catch(err => {
        console.log(1);
      });
    };

    let faqModule = reactive({
      faqContent: '',
      getFaqFn
    });
    return { ...toRefs(faqModule)
    };
  }

});