import { Request } from '../request'

// 获取特性
export function getFeatures(parameter: {page: string, size: string}):any {
  return Request.axiosInstance({
    url: '/features',
    method: 'get',
    params: parameter
  })
}
