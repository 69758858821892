import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "personOrderForm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "auto",
    "label-position": "left",
    ref: "formRef",
    validate: "validate"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: _ctx.$t('Applicant'),
      prop: "name",
      class: "is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.name = $event),
        disabled: "",
        placeholder: "name"
      }, null, 8, ["modelValue"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Contact Email'),
      prop: "email",
      class: "is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.email = $event),
        disabled: "",
        placeholder: "email"
      }, null, 8, ["modelValue"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Country'),
      prop: "country",
      class: "is-required item_block"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.country,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.form.country = $event),
        filterable: "",
        class: "block",
        placeholder: "Country"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.country, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }, 8, ["label"]), _ctx.oneYuan === '1' ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 0,
      label: _ctx.$t('Job Title'),
      prop: "position",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.position,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.form.position = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Please select your job title')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positionArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"])) : _createCommentVNode("", true), _ctx.oneYuan === '1' ? (_openBlock(), _createBlock(_component_el_form_item, {
      key: 1,
      label: _ctx.$t('Purpose'),
      prop: "purpose",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.purpose,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.form.purpose = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Please select your purpose of use')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.useArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Company Name'),
      prop: "company",
      class: _normalizeClass(["label-w", {
        'is-required': _ctx.oneYuan === '1'
      }])
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.company,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.form.company = $event),
        placeholder: _ctx.$t('Please enter company name')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label", "class"])]),
    _: 1
  }, 8, ["model", "rules"])]);
}