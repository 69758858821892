import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
export default defineComponent({
  name: 'UserCenter',
  components: {},

  setup() {
    let route = useRoute();
    let router = useRouter();

    const changeTab = function (TabPanelName) {
      router.push({
        name: TabPanelName
      });
    };

    onBeforeRouteUpdate(({
      name
    }) => {
      if (name != centerModule.activeIndex) {
        centerModule.activeIndex = name;
      }
    });
    onMounted(() => {
      if (route.name !== centerModule.activeIndex) {
        centerModule.activeIndex = route.name;
      }
    });
    let centerModule = reactive({
      activeIndex: 'profile',
      changeTab,
      route
    });
    return { ...toRefs(centerModule)
    };
  }

});