import { defineComponent, reactive, onUnmounted, computed, toRefs } from 'vue';
import downGoby from '@/utils/downGoby';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    keys: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    let {
      keys
    } = toRefs(props);
    let store = useStore();
    let className = computed(() => {
      return store.state.downList.find(item => item.id == keys.value)?.name || '';
    });
    let {
      currIndex,
      changeCurrIndex,
      down,
      show,
      changeShow
    } = downGoby();

    const clickEvent = function () {
      setupData.show = false;
    };

    document.body.addEventListener('click', clickEvent, false);
    onUnmounted(() => {
      document.body.removeEventListener('click', clickEvent);
    });
    let setupData = reactive({
      currIndex,
      changeCurrIndex,
      down,
      show,
      changeShow,
      className
    });
    return setupData;
  }

});