import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue';
import { i18n } from '@/assets/i18n';
import { getVersions } from '@/api/home/home';
import formatDate from '@/utils/date';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import routerJump from '@/utils/router';
import down from "@/utils/down";
import 'swiper/css';
import 'swiper/css/pagination';
export default defineComponent({
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const lang = ref(i18n.global.locale.value);
    onMounted(() => {
      getVersionsFn();
    }); // 获取版本号

    const getVersionsFn = () => {
      getVersions().then(res => {
        if (res.statusCode === 200) {
          homeModule.version = res.data[0].version;
          homeModule.version_base = res.data[0].version_base;
          homeModule.activeVerison = res.data[0].version;
          homeModule.versionsList = res.data.slice(0, 3);
          homeModule.versionsList.forEach((v, k) => {
            // v.winUrl = `https://gobies.org/goby-win-x64-${v.version}.zip`;
            // v.darwinUrl = `https://gobies.org/goby-darwin-x64-${v.version}.zip`;
            // v.linuxUrl = `https://gobies.org/goby-linux-x64-${v.version}.zip`;
            v.date = formatDate(v.created_at);
          });
          console.log(homeModule.versionsList, '==');
        }
      }).catch(err => {
        console.log(err);
        console.log(1);
      });
    }; // 折叠显示


    const toggleShow = version => {
      homeModule.activeVerison = version;
    }; // 切换显示视频弹窗


    const toggleVideoPopUp = (type, src, title) => {
      homeModule.showVideo = type ? true : false;
      homeModule.videoSrc = src;
      homeModule.videoTitle = title;
    };

    const jump = (path, type) => {
      homeModule.addLog(type);
      homeModule.toPage(path);
    };

    const toPage = path => {
      routerJump(path);
    };

    const addLog = type => {
      process.env.NODE_ENV === 'production' && window._hmt.push(['_trackEvent', type, 'click', 'payment']);
    };

    let homeModule = reactive({
      lang,
      versionsList: [],
      activeVerison: '',
      version: '',
      version_base: '',
      videoSrc: '',
      videoTitle: '',
      showVideo: false,
      modules: [Pagination],
      videoSrcMap: {
        '1': 'https://goby-assets.oss-cn-beijing.aliyuncs.com/static_front/img/whole-process.mp4',
        '2': 'https://goby-assets.oss-cn-beijing.aliyuncs.com/static_front/img/session.mp4',
        '3': 'https://goby-assets.oss-cn-beijing.aliyuncs.com/static_front/img/img/custom-vulnerabilities.mp4'
      },
      toggleShow,
      toggleVideoPopUp,
      down,
      toPage,
      addLog,
      jump
    });
    return { ...toRefs(homeModule)
    };
  }

});