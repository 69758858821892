import { i18n } from '@/assets/i18n'
import Extensions from '@/views/extensions/Extensions.vue'
import DocZh from '@/views/extensions/DocZh.vue'
import DocEn from '@/views/extensions/DocEn.vue'
let Doc

switch (i18n.global.locale.value) {
	case 'en':
		Doc = DocEn
		break;
	case 'zh':
		Doc = DocZh
		break;
	default:
		break;
}



export default {
  routers: [
		{
			path: '/extensions',
			name: 'extensions',
			component: Extensions
		},
		{
			path: '/doc',
			name: 'doc',
			component: Doc
		}
	]
}