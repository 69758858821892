import { defineComponent, onMounted, onUnmounted, reactive } from 'vue';
import { checkPayStatus, orderDetailWithPayInfo, paymentType } from "@/api/order/order";
import { useRoute, useRouter } from "vue-router";
import copy from "copy-to-clipboard";
import { ElMessage } from "element-plus";
import QrcodeVue from 'qrcode.vue';
export default defineComponent({
  name: "CheckStand",
  components: {
    QrcodeVue
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const order_no = route.params.order_no;

    const handleClick = tab => {
      orderDetailWithPayInfo({
        order_no,
        payment_type: tab.paneName
      }).then(({
        data
      }) => {
        state.activeKey = data.payment_type;
        state.order = data;
        if (data?.info?.qrcode_url) state.qrCode = data?.info?.qrcode_url;else state.qrCode = '';
      });
    };

    const copyKey = val => {
      let copyContent = '';
      if (!val) return;
      copyContent = val;
      copy(copyContent);
      ElMessage({
        showClose: true,
        message: 'Success!',
        type: 'success'
      });
    };

    const checkPayStatusBlocked = () => {
      checkPayStatus({
        order_no
      }).then(({
        data: {
          status
        }
      }) => {
        if (status) {
          router.push({
            name: 'payResultSuccess',
            params: {
              oneYuan: state.oneYuan
            }
          });
        }

        return checkPayStatusBlocked();
      });
    };

    const state = reactive({
      paymentList: {},
      stopCheck: false,
      activeKey: "",
      qrCode: '',
      handleClick,
      copyKey,
      checkPayment: () => state?.order?.payment_type === "company_pay",
      order: {
        payment_type: 'wechat_pay'
      },
      oneYuan: '0'
    });
    onUnmounted(() => {
      state.stopCheck = true;
    });
    onMounted(() => {
      let oneYuan = route.params.oneYuan;
      state.oneYuan = oneYuan;
      checkPayStatusBlocked();
      paymentType().then(({
        data
      }) => {
        if (state.oneYuan === '1') {
          if (data.company_pay) delete data.company_pay;
        }

        if (data.paypal) delete data.paypal;
        state.paymentList = data;
      });
      orderDetailWithPayInfo({
        order_no
      }).then(({
        data
      }) => {
        state.activeKey = data.payment_type;
        state.order = data;

        if (data?.info?.qrcode_url) {
          state.qrCode = data.info.qrcode_url;
        }
      });
    });
    return state;
  }

});