import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-04340160"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "trialSuccess bgfff"
};
const _hoisted_2 = {
  class: "height34"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_downGoby = _resolveComponent("downGoby");

  const _component_el_result = _resolveComponent("el-result");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_result, {
    icon: "success",
    title: _ctx.$t('Submitted successfully')
  }, {
    extra: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_downGoby, {
      keys: _ctx.edition
    }, null, 8, ["keys"])])]),
    _: 1
  }, 8, ["title"])]);
}