import { defineComponent, reactive } from 'vue';
import { updatePassword } from '@/api/user/user';
import { ElMessage } from 'element-plus';
import { getUserInfo } from '@/utils/auth';
export default defineComponent({
  name: 'ModifyInfo',

  setup() {
    // 获取个人信息
    const USERINFO = getUserInfo(); // 修改密码

    const updatePasswordFn = () => {
      updatePassword({
        password: modifyInfoModule.password,
        newPassword: modifyInfoModule.newPassword,
        confirmNewPassword: modifyInfoModule.confirmNewPassword
      }).then(res => {
        if (res.statusCode === 200) {
          ElMessage({
            message: 'Success',
            type: 'success'
          });
        }
      });
    };

    let modifyInfoModule = reactive({
      password: '',
      newPassword: '',
      confirmNewPassword: '',
      USERINFO,
      updatePasswordFn
    });
    return modifyInfoModule;
  }

});