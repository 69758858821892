import { Request } from '../request'

//获取用户license列表
export function licenseList(params:{
    page: number,
    size: number
}){
    return Request.axiosInstance({
        url: "/license/list",
        method: 'get',
        params
    })
}

//根据id生成license
export function generateLicense(params:{
    mid:string,
    license_id: number
    email?:string
}){
    return Request.axiosInstance({
        url: "/license/generateById",
        method: 'get',
        params
    })
}

// 获取license类型
export function licenseCategory(){
    return Request.axiosInstance({
        url: '/license/category',
        method: 'get'
    })
}

// 根据license_type id获取详情
export function licenseCategoryDetail(params: {
    license_type_id: string
}){
    return Request.axiosInstance({
        url: '/license/category/detail',
        method: 'get',
        params
    })
}

//获取可用激活码
export function licenseAvailable(params:{
    page: number,
    size: number
}){
    return Request.axiosInstance({
        url: '/license/available',
        method: 'get',
        params
    })
}

// 导出激活码列表
export function licenseExportUserKeys(data:{
    license_ids: Array<number>
}){
    return Request.axiosInstance({
        url: '/license/exportUserKeys',
        method: 'post',
        data
    })
}