import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-8b07f278"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "chat"
};
const _hoisted_2 = {
  class: "title1"
};
const _hoisted_3 = {
  key: 0,
  class: "font16 label-color text-capitalize"
};
const _hoisted_4 = {
  key: 1,
  class: "font16 label-color text-capitalize"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_form_item = _resolveComponent("el-form-item");

  const _component_el_option = _resolveComponent("el-option");

  const _component_el_select = _resolveComponent("el-select");

  const _component_el_col = _resolveComponent("el-col");

  const _component_el_row = _resolveComponent("el-row");

  const _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.$t("Talk to our sales team")) + " ", 1), _ctx.edition == '15' ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ( " + _toDisplayString(_ctx.$t('Red Team Enterprise Edition')) + " ) ", 1)) : _createCommentVNode("", true), _ctx.edition == '17' ? (_openBlock(), _createElementBlock("span", _hoisted_4, " ( " + _toDisplayString(_ctx.$t('Blue Team Enterprise Edition')) + " ) ", 1)) : _createCommentVNode("", true)]), _createVNode(_component_el_form, {
    model: _ctx.form,
    rules: _ctx.rules,
    "label-width": "auto",
    "label-position": "left",
    ref: "formRef"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: _ctx.$t('Applicant'),
      prop: "name",
      class: "is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.name = $event),
        placeholder: _ctx.$t('Please enter your first and last name')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Phone'),
      prop: "mobile",
      class: "label-w is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.mobile,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.mobile = $event),
        placeholder: _ctx.$t('Your true and accurate phone number')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Contact Email'),
      prop: "email",
      class: "label-w"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.form.email = $event),
        placeholder: _ctx.$t('Please enter your current email address')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Country'),
      prop: "country",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.country,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.form.country = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Country')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.country, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Job Title'),
      prop: "position",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.position,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.form.position = $event),
        filterable: "",
        class: "block",
        placeholder: _ctx.$t('Please select your job title')
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.positionArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Purpose'),
      prop: "purpose",
      class: "item_block is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_select, {
        modelValue: _ctx.form.purpose,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.form.purpose = $event),
        placeholder: _ctx.$t('Please select your purpose of use'),
        filterable: "",
        class: "block"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.useArr, (item, key) => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key,
            label: item.text,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Company Name'),
      prop: "company",
      class: "label-w is-required"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.company,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.form.company = $event),
        placeholder: _ctx.$t('Please enter company name')
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Quantity'),
      class: "label-w"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        gutter: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 12
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _ctx.form.number,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.form.number = $event),
            modelModifiers: {
              number: true
            },
            placeholder: _ctx.$t('Purchase quantity')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      label: _ctx.$t('Message'),
      class: "label-w"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: _ctx.form.comment,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.form.comment = $event),
        placeholder: _ctx.$t('Please describe your requirement and free-time'),
        type: "textarea",
        rows: 5
      }, null, 8, ["modelValue", "placeholder"])]),
      _: 1
    }, 8, ["label"]), _createVNode(_component_el_form_item, {
      class: "mt60 mb10"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        onClick: _cache[9] || (_cache[9] = //@ts-ignore
        (...args) => _ctx.submit && _ctx.submit(...args)),
        class: "width187 height46 colorFFF bgColorMain lh46 ac br4 pointer"
      }, _toDisplayString(_ctx.$t("Contact sales")), 1)]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createElementVNode("div", null, [_createTextVNode(_toDisplayString(_ctx.$t('By submitting this, you agree to')) + " ", 1), _createElementVNode("span", {
        class: "colorMain pointer",
        onClick: _cache[10] || (_cache[10] = $event => _ctx.toPage('/agreement'))
      }, "《" + _toDisplayString(_ctx.$t("Goby user registration and service agreement")) + "》", 1)])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"])]);
}