import { createStore } from 'vuex'
export default createStore({
  state: {
    userInfo:{

    },
    licenseCategory: [],
    downList:[]
  },
  getters: {
  },
  mutations: {
    changeUserInfo(state, val) {
      state.userInfo = val
    },
    changeLicenseCategory(state, val){
      state.licenseCategory = val
    },
    setDownList(state,val){
      state.downList = val
    }
  },
  actions: {
   
  },
  modules: {
  }
})
