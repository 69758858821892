import "core-js/modules/es.error.cause.js";
import { defineComponent, reactive, ref, onMounted } from 'vue'; // import { getCountryList } from "@/api/country/country"

import { i18n } from '@/assets/i18n';
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import { getDict } from '@/api/dict/dict';
export default defineComponent({
  props: ['oneYuan'],

  setup(props) {
    let store = useStore();
    let rules = {
      name: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!String(value).trim()) {
            return callback(i18n.global.t('First and last name'));
          }

          return callback();
        }

      },
      email: {
        type: 'string',
        trigger: 'blur',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please enter your email.')));
          }

          const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

          if (!reg.test(value)) {
            return callback(new Error(i18n.global.t('Please enter the correct email address.')));
          }

          return callback();
        }

      },
      country: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please select country')));
          }

          return callback();
        }

      },
      position: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please select position')));
          }

          return callback();
        }

      },
      purpose: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!value) {
            return callback(new Error(i18n.global.t('Please select purpose')));
          }

          return callback();
        }

      },
      company: {
        type: 'string',
        trigger: 'change',

        validator(rule, value, callback) {
          if (!value && props.oneYuan === '1') {
            return callback(new Error(i18n.global.t('Please enter company name')));
          }

          return callback();
        }

      }
    };
    const formRef = ref();

    let verify = function (callback) {
      setupData?.formRef?.validate(valid => {
        callback(valid);
      });
    };

    const local = useI18n();

    const init = function () {
      getDict({
        dicts: ['country', 'position', 'use']
      }).then(res => {
        setupData.country = res.data.country;
        setupData.positionArr = res.data.position;
        setupData.useArr = res.data.use;
        setupData.form.country = local.locale.value === 'en' ? 'US' : 'CN';
      });
      store.state.userInfo.email && (setupData.form.email = store.state.userInfo.email);
      store.state.userInfo.name && (setupData.form.name = store.state.userInfo.name);
      store.state.userInfo.country && (setupData.form.country = store.state.userInfo.country);
    };

    onMounted(() => {
      init();
    });
    let setupData = reactive({
      form: {
        name: "",
        email: "",
        company: "",
        country: "",
        position: "",
        purpose: ""
      },
      country: [],
      rules,
      verify,
      formRef,
      positionArr: [],
      useArr: []
    });
    return setupData;
  }

});