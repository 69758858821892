import { defineComponent, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
  name: "PayView",

  setup() {
    let route = useRoute();
    let router = useRouter();

    if (!route.params?.total) {
      router.replace({
        name: "buy"
      });
    }

    let setupData = reactive({
      total: route.params?.total,
      orderNumber: route.params?.orderNumber
    });
    return setupData;
  }

});