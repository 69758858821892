import {Request} from '../request'
import {getLang} from '@/utils/lang'
//创建订单
export function orderAdd(data: {
    license_type_id: string | string[]
    payment_type: string,
    quantity: number
    name: string
    country: string
    company: string,
    period: number,
    coupon_code?:string
}) {
    return Request.axiosInstance({
        url: 'order/add',
        method: 'post',
        data
    })
}

// 获取paypal支付地址
export function orderPay(params: {
    order_no: string
}) {
    return Request.axiosInstance({
        url: '/pay/paypal/url',
        method: 'get',
        params
    })
}

// paypal支付回调
export function apiOrderPayCallback(data: {
    PayerID: string
    orderNo: string
    paymentId: string
    orderId: string
    success: string
    token: string
}) {
    return Request.axiosInstance({
        url: "/pay/paypal/callback",
        method: 'post',
        data
    })
}


//微信支付二维码
export const wechatPayCallback = (params: {
    order_no: string
}) => {
    return Request.axiosInstance({
        url: "/pay/wechat/codeUrl",
        method: 'get',
        params
    })
}

//获取订单支付状态
export const PayCallback = (params: {
    order_no: string
}) => {
    return Request.axiosInstance({
        url: "/pay/wechat/codeUrl",
        method: 'get',
        params
    })
}


// 联系我们
export function contact(data: {
    name: string
    email: string
    mobile: string
    company: string
    country: string
    quantity: number
    comment: string
    position: string
    use: string,
    license_type_id: number
}) {
    return Request.axiosInstance({
        url: "/contact",
        method: 'post',
        data
    })
}

// 获取用户协议
export function getPocdoc() {
    const type = getLang() === "zh" ? 0 : 1;
    return Request.axiosInstance({
        url: "/get-pocdoc",
        method: 'post',
        data: {
            type
        }
    })
}