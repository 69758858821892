import { defineComponent, ref } from "vue";
import { i18n } from "@/assets/i18n";
export default defineComponent({
  name: "FooterCpt",

  setup() {
    const lang = ref(i18n.global.locale.value);
    return {
      lang
    };
  }

});