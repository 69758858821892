import { defineComponent, reactive } from 'vue';
import country from "@/assets/data/country.json";
export default defineComponent({
  name: "VerifyOrderForm",
  props: ['form', 'oneYuan'],

  setup() {
    let setupData = reactive({
      country
    });
    return setupData;
  }

});