import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderCpt = _resolveComponent("HeaderCpt");

  const _component_el_affix = _resolveComponent("el-affix");

  const _component_router_view = _resolveComponent("router-view");

  const _component_FooterCpt = _resolveComponent("FooterCpt");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_affix, {
    offset: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_HeaderCpt)]),
    _: 1
  }), _createElementVNode("div", {
    class: _normalizeClass(["main", {
      mainApp: _ctx.mainApp
    }])
  }, [_createVNode(_component_router_view)], 2), _createVNode(_component_FooterCpt)], 64);
}