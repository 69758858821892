import { defineComponent, reactive, onMounted } from 'vue';
import { activate } from '@/api/user/user';
import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
  name: 'ActivateMailboxResult',

  setup() {
    onMounted(() => {
      activateEmail();
    });
    const router = useRouter();
    const route = useRoute();
    const Token = route.query.token; // 激活邮箱

    const activateEmail = () => {
      activate({
        token: Token
      }).then(res => {
        if (res.statusCode === 200) {
          activateModule.showSuccess = true;
          setTimeout(() => {
            toLogin();
          }, 3000);
        } else {
          activateModule.showFail = true;
          activateModule.failMsg = res.messages;
        }
      }).catch(err => {
        activateModule.showFail = true;
        activateModule.failMsg = err.messages;
      });
    }; // 去登陆


    const toLogin = () => {
      router.replace({
        name: "SignIn"
      });
    };

    let activateModule = reactive({
      showSuccess: false,
      showFail: false,
      failMsg: '',
      toLogin
    });
    return activateModule;
  }

});