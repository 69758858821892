import Updates from '@/views/updates/Updates.vue'

export default {
  routers: [
		{
			path: '/updates',
			name: 'updates',
			component: Updates
		}
	]
}