import { defineComponent, reactive, onMounted } from 'vue';
import { getSignUpInfo, getRouterFrom } from '@/utils/auth';
import { resendActiveMail } from '@/api/user/user';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'ActivateMailbox',

  setup() {
    onMounted(() => {
      const RouterFrom = getRouterFrom();

      if (RouterFrom == 'profile') {
        resendActiveMailFn();
      }
    }); // 重发激活邮件

    const resendActiveMailFn = () => {
      resendActiveMail({
        id: getSignUpInfo().id
      }).then(res => {
        if (res.statusCode === 200) {
          ElMessage({
            message: 'Success',
            type: 'success'
          });
        }
      });
    };

    let activateModule = reactive({
      email: getSignUpInfo().email,
      resendActiveMailFn
    });
    return activateModule;
  }

});